import React from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import ArticleFooterText from './ArticleFooterText';

import './styles.scss';

export const query = graphql`
    query ArticleFooterQuery {
        profileImage: file(absolutePath: { regex: "/profile-picture.jpg/" }) {
            childImageSharp {
                gatsbyImageData(
                    layout: FIXED,
                    width: 75,
                    height: 75
                )
            }
        }
    }
`;

export default class BlogFooter extends React.Component {
    render () {
        return (
            <StaticQuery query={query} render={(props) => (
                <footer className='article-footer'>

                    <Link to='/'>
                        <GatsbyImage
                            image={getImage(props.profileImage)}
                            imgClassName='article-footer-image'
                            alt='Rob Farr' />
                    </Link>

                    <div className='article-footer-text'>
                        <ArticleFooterText />
                    </div>

                </footer>
            )} />
        );
    }
}