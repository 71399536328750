import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout/';
import ArticleFooter from '../components/ArticleFooter/';

export const pageQuery = graphql`
    query PageBySlug($slug: String!) {
        markdownRemark(fields: { slug: { eq: $slug } }) {
            id
            html
            frontmatter {
                title
                showFooter
            }
        }
    }
`;

export default class PageTemplate extends React.Component {

    page () {
        return this.props.data.markdownRemark;
    }

    render () {
        const { html, frontmatter } = this.page();
        const { title, showFooter } = frontmatter;
        return (
            <Layout title={title}>
                <article>

                    <header>
                        <h1>{title}</h1>
                    </header>

                    <section dangerouslySetInnerHTML={{ __html: html }} />

                    { showFooter !== 'false' ? <ArticleFooter /> : null }

                </article>

            </Layout>
        );
    }

}
